<template>
  <v-app>
    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view />

    <!--
    <dashboard-core-settings />
    -->
  </v-app>
</template>

<script>
  import { FETCH_BEDS, FETCH_MORPHOLOGIES, FETCH_REGIONS, FETCH_RIVERS, FETCH_TENANTS } from '@/store/actions.type'
  import store from '@/store'
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      // DashboardCoreSettings: () => import('./components/core/Settings'),
      DashboardCoreView: () => import('./components/core/View'),
    },

    data: () => ({
      expandOnHover: false,
    }),
    created: () => {
      store.dispatch(FETCH_BEDS)
      store.dispatch(FETCH_MORPHOLOGIES)
      store.dispatch(FETCH_REGIONS)
      store.dispatch(FETCH_RIVERS)
      store.dispatch(FETCH_TENANTS)
    },
  }
</script>
